<!-- 关注/被关注 用户 组件 -->
<template>
<div class="subscribe-user-item-outside-ctn">
    <div class="subscribe-user-item-ctn">
        <div class="item-left-ctn">
            <div class="user-ctn">
                <div class="user-avatar">
                    <a :href="getUserHref" target="_blank">
                        <Avatar :name="userData.name" :url="userData.avatar" :size="48"> </Avatar>
                    </a>
                </div>
                <div class="user-text">
                    <div class="name-pos">
                        <div class="user-name">
                            {{  userData.name  }}
                        </div>
                        <!-- <div class="user-pos">
                            学生
                        </div> -->
                    </div>
                    <div class="user-description">
                        {{  userData.biography  }}
                    </div>
                </div>
            </div>
            <!-- <div class="user-info-items">
                <div class="user-info-item">
                    文章 (10)
                </div>
                <div class="user-info-item">
                    粉丝 (16)
                </div>
            </div> -->
        </div>
        <div class="item-right-ctn" v-if="!isSelf">
            <a-button type="primary" class="sub-btn" v-if="userData.hasFollow == 1" @click="handleSubToggle" :loading="isFollowing" :disabled="isFollowing" @mouseover="(e) => handleRecentSubscribeMouseIn(e)"
            @mouseleave="(e) => handleRecentSubscribeMouseLeave(e)"> + 关注 </a-button>
            <a-button type="plain" :class="['unsub-btn', isHover ? 'hover-btn':'' ]"
            @mouseover="(e) => handleRecentSubscribeMouseIn(e)"
            @mouseleave="(e) => handleRecentSubscribeMouseLeave(e)"
            @click="handleSubToggle"
            :loading="isFollowing"
            :disabled="isFollowing"
            v-else>
            <span v-if="isHover">取消关注 </span> 
            <div v-else-if="userData.hasFollow == 3">
                <a-icon type="swap" /> 互相关注
            </div>
            <div v-else>
                已关注
            </div>
               <!--  <span v-if="isHover">取消关注 </span> 
                <span v-else-if="isMutured"> <a-icon type="swap" /> <span> 互相关注 </span> </span>
                <span v-else> 已关注 </span> -->
            </a-button>
        </div>
    </div>
</div>
</template>

<script>
import { computed, ref } from 'vue-demi';
import Avatar from '../../components/avatar.vue';
import {  followUser, unFollowUser  } from '@/api/paperManage.js';
import { message } from 'ant-design-vue';
import store from '../../../../store';

export default {
    props: {
        index: {
            default: -1
        },
        userData: {
            default: () => ({})
        }
    },
    setup(props, { root }) {
        const isMutured = computed(() => {
            return  props.index % 2 == 0;
        })
        // 是否已关注
        const isSub = ref(false);
        // 是否已关注
        const isHover = ref(false);

        const isSelf = computed(() => {
            return  props.userData.id === store.state.userInfo.userId;
        })

        // 鼠标移动至已关注/互相关注按钮上
        const handleRecentSubscribeMouseIn = () => {
            isHover.value = true;
        }
        // 鼠标移出
        const handleRecentSubscribeMouseLeave = () => {
            isHover.value = false;
        }
        // 关注/未关注切换
        const handleSubToggle = async () => {
            const hasFollow = props.userData.hasFollow != 1 ;
            isFollowing.value = true;

            if(hasFollow) {
                await unfollow();
            }
            else {
                await follow();
            }

            /* setTimeout(() => {
                isSub.value = !isSub.value;
                isHover.value = false;
                isSubLoading.value = false;
            },2000) */
        }

        const getUserHref = computed(() => {
            const { $router } = root;
            const href = $router.resolve({
                name: 'bbsUser',
                params: {
                    id: props.userData.id
                }
            })
            return href.href
        })

        const isFollowing = ref(false);  // 是否正在关注中

       const follow = async () => {
        isFollowing.value = true;
        const res = await followUser({
            userid: props.userData.id
        });
        if(res.success) {
            message.success('成功关注')
           props.userData.hasFollow = 2;
        }
        isFollowing.value = false;
       }
       const unfollow = async () => {
        isFollowing.value = true;
        const formData = new FormData();
        formData.append('userid',props.userData.id)
        const res = await unFollowUser(formData);
        if(res.success) {
            message.info('已取消关注')
            props.userData.hasFollow = 1
        }
        isFollowing.value = false;
       }
        return {
            isMutured,
            isSub,
            isHover,
            handleRecentSubscribeMouseIn,
            handleRecentSubscribeMouseLeave,
            handleSubToggle,
            getUserHref,
            isFollowing,
            isSelf

        };
    },
    components: { Avatar }
}
</script>

<style lang="less" scoped>
.subscribe-user-item-outside-ctn {
    padding: 0px 20px;
    transition: .3s all ease-in-out;
    &:hover {
        background: #f6f6f6;
    }
}
.subscribe-user-item-ctn {
    display: flex;
    align-items: center;
    border-bottom: .5px solid #EBEBEB;
    padding: 12px 0px;
    .item-left-ctn {
        width: 0;
        flex: 1;
        .user-ctn {
            display: flex;
            
            .user-text {
                margin-left: 10px;
                .name-pos {
                    display: flex;
                    align-items: center;
                    .user-name {
                        color: rgba(0, 0, 0, 0.85);
                        font-size: 16px;
                    }
                    .user-pos {
                        font-size: 12px;
                    color: #8590A6;
                    padding: 0px 8px;
                    margin-left: 16px;
                    position: relative;
                    z-index: 100;

                    &:before {
                        content: '';
                        position: absolute;
                        width: 6px;
                        height: 10px;
                        top: 2px;
                        left: 0px;
                        z-index: 10;
                        border-left: 0.5px solid #8590A6;
                        border-top: 0.5px solid #8590A6;
                    }

                    &:after {
                        content: '';
                        position: absolute;
                        width: 6px;
                        height: 10px;
                        bottom: 2px;
                        right: 0px;
                        z-index: 10;
                        border-right: 0.5px solid #8590A6;
                        border-bottom: 0.5px solid #8590A6;
                    }
                    }
                }
                .user-description {
                    color: rgba(0, 0, 0, 0.65);
                    line-height: 22px;
                    margin-top: 4px;
                }
            }
        }
        .user-info-items {
            display: flex;
            margin-top: 8px;
            align-items: center;
            color: rgba(0, 0, 0, 0.45);
            font-size: 12px;
            margin-left: 58px;
            .user-info-item {
                min-width: 100px;
            }
            .user-info-item + .user-info-item {
                margin-left: 16px;
            }
        }
    }
    .item-right-ctn {
        margin-left: 20px;
        .sub-btn, .unsub-btn {
            width: 110px;
            span {
                text-align: center;
            }
            text-align: center;
        }
        .unsub-btn {
            background: #EEEEEE;
            border: .5px solid transparent;
            color: rgba(0, 0, 0, 0.65);
            transition: .3s all ease-in-out;
        }
        .hover-btn {
            border: .5px solid @srims-primary-color;
            color: @srims-primary-color;
        }
    }
}
</style>